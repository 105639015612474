@import './tailwind.css';

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  /* Prevent elastic scrolling */
  -webkit-overflow-scrolling: touch;
}
*{
  scrollbar-width: thin;
  scrollbar-color: var(--color-base-400) var(--color-base-200);
  -webkit-scrollbar-width: thin;
}

:root {
  // text
  --text-grey: #959595;
  --text-dark-grey: #616161;
  --text-black: #2c2c2c;

  // btn
  --btn-light-green: #daf3eb;
  --btn-white-7: rgba(255, 255, 255, 0.7);
  --btn-white-9: rgba(255, 255, 255, 0.9);
  --btn-grey: #f5f5f5;
  --btn-extra-light-green: #f4f6fb;
  --btn-light-orange: rgba(255, 171, 85, 0.15);

  // icon
  --icon-grey: #b1b1b1;
  --icon-dark-grey: #7c7c7c;

  // border
  --border-grey: #ebebeb;

  // color
  --white: #ffffff;
  --blue: #086ada;
  --green: #00a389;
  --light-green: #2aaa7f;
  --dark-green: #095d41;
  --red: #f23535;
  --light-red: rgba(255, 12, 12, 0.2);
  --orange: #ffab55;
  --body-bg: #f2f4f6;
  --light-blue: #d1e2f3;
  --black-5: rgba(0, 0, 0, 0.5);
  --black-8: rgba(0, 0, 0, 0.8);
  --color-base-400:#a3a3a3;
  --color-base-200:#e5e5e5;
}

body {
  overscroll-behavior: none;
  /* Prevent elastic scrolling */
  -webkit-overflow-scrolling: touch;
  font-family: 'Inter', sans-serif;
  overflow-y: auto;
  @apply m-0 w-full overflow-x-hidden scroll-smooth bg-[#f2f4f6] #{!important};

  // &::-webkit-scrollbar {
  //   @apply absolute h-[0.2rem] w-[0.2rem] bg-transparent #{!important};
  // }

  // &::-webkit-scrollbar-thumb {
  //   @apply rounded-lg bg-[#00A389];
  // }

  .ant-modal-wrap {
    @apply overflow-hidden;
  }

  .ant-modal,
  .ant-drawer,
  .ant-collapse {
    transition:
      transform 0s ease-in-out,
      opacity 0s ease-in-out !important;
  }
}

html {
  @apply scroll-smooth #{!important};
}

.customInput {
  @apply rounded-l;

  &::placeholder,
  input::placeholder {
    @apply text-left text-sm font-normal tracking-normal text-[#959595];
  }
}

.placeholder {
  @apply text-left text-sm font-medium tracking-normal text-[#344054];
}

.mobileD {
  @media (min-width: 768px) {
    @apply hidden #{!important};
  }
}

.mobileTopFilter {
  // &::-webkit-scrollbar {
  //   @apply h-[0.188rem] w-[0.188rem] bg-transparent;
  // }
}

.mainFilter {
  // &::-webkit-scrollbar {
  //   @apply h-[0.188rem] w-[0.188rem] bg-transparent;
  // }

  // &::-webkit-scrollbar-thumb {
  //   @apply bg-[#00A389];
  // }
}

.reelsScroll {
  // &::-webkit-scrollbar {
  //   @apply h-[0.188rem] w-0 bg-transparent md:w-2.5;
  // }

  // &::-webkit-scrollbar-thumb {
  //   @apply w-1 bg-transparent;
  // }
}

.ant-message-notice-content {
  @apply rounded-md;

  > div {
    @apply flex items-center gap-1;
  }
}

.c-bQoszf {
  display: none !important;
}

input,
textarea,
select {
  font-size: 16px !important;
}

.customIcon {
  @apply h-8 w-8;

  @media screen and (max-width: 767px) {
    @apply h-6 w-6;
  }
}
